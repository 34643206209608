import React from 'react'

import BaseContainer from 'src/components/BaseContainer'
import Header from 'src/components/Header'
import CareerPage from 'src/components/CareerPage'
import Footer from 'src/components/Footer'
import Meta from 'src/components/Meta'
import ogLogoCompany from 'src/images/optimized/jpg/cases/og-logo_company.jpg'

const metaData = {
    title: 'Calaton Studio - Careers',
    description: 'At Calaton Studio we are always looking for experienced professionals.',
    url: 'https://www.calaton.studio/careers',
    img: `https://www.calaton.studio${ogLogoCompany}`,
    keywords: 'Calaton Studio, careers, resume',
}

const Career = () => (
    <>
        <Meta content={metaData} />
        <BaseContainer>
            <Header />
            <CareerPage />
            <Footer />
        </BaseContainer>
    </>
)

export default Career
