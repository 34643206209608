import React from 'react'
import classNames from 'classnames'
import nextId from 'react-id-generator'

import SvgSpriteIcon from 'src/components/SvgSprite/SvgSpriteIcon'
import Requirements from './Requirements'

import styles from './styles.module.scss'

const CareerPage = () => {
    const vacanciesList = [
        {
            vacancy: 'UX/UI Designer',
            requirements: [
                'At least 1 year of experience in product design and creation;',
                'Strong Research, UX and UI skills; deep knowledge of Figma, Photoshop, Illustrator;',
                'Experience with wireframes, visual mockups, and prototypes;',
                'Proficiency in English at the Intermediate level;',
                'Systematic approach to product design; availability of a portfolio.',
            ],
            id: nextId(),
            checked: true,
        },
    ]
    const containerDivClass = classNames('container', styles.wrapper)
    return (
        <>
            <section className={styles.career}>
                <div className={containerDivClass}>
                    <h1 className={styles.title}>Careers</h1>
                    <p className={styles.text}>
                        We are growing, and therefore we are looking for experienced professionals
                        to work on large projects.
                        <br />
                        We build strong teams for complex tasks.
                    </p>
                    <ul className={styles.vacanciesList}>
                        {vacanciesList.map(({ vacancy, requirements, id, checked }) => (
                            <li key={vacancy} className={styles.vacanciesItem}>
                                <input
                                    id={id}
                                    type="checkbox"
                                    className={styles.checkbox}
                                    defaultChecked={checked}
                                />
                                <label htmlFor={id} className={styles.vacancyName}>
                                    <SvgSpriteIcon name="drop-down" className={styles.downIcon} />
                                    {vacancy}
                                </label>
                                <Requirements requirements={requirements} />
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        </>
    )
}

export default CareerPage
