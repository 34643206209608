import React from 'react'
import PT from 'prop-types'
import nextId from 'react-id-generator'

import styles from './styles.module.scss'

const Requirements = ({ requirements }) => {
    return (
        <div className={styles.requirementContainer}>
            <span className={styles.listTitle}>Requirements:</span>
            <ul className={styles.requirementList}>
                {requirements.map(requirement => (
                    <li key={nextId()} className={styles.requirementItem}>
                        <span className={styles.strip}>–</span>
                        <span>{requirement}</span>
                    </li>
                ))}
            </ul>
            <button className={styles.resumeBtn}>Send resume</button>
        </div>
    )
}

Requirements.propTypes = {
    requirements: PT.array,
}

export default Requirements
